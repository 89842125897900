import { gsap, Power1 } from "@gsap/shockingly/dist/gsap"
import { DrawSVGPlugin } from "@gsap/shockingly/DrawSVGPlugin"
// import { MotionPathHelper } from "@gsap/MotionPathHelper"
import { MotionPathPlugin } from "@gsap/shockingly/MotionPathPlugin"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"

import asset from "~images/asset.png"
import asset2 from "~images/asset2.png"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import ModalContext from "../contexts/ModalContext"
import LogoImage from "../images/logo_dark.png"
import Hidden from "./Hidden"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
const LeftSide = styled(Col)`
  /* padding-left: 14px; */
`
const RightSide = styled(Col)`
  /* padding-right: 14px; */
  /* @media (max-width: 1280px) {
    padding-right: 16px;
  } */
`

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100px;

  @media ${({ theme }) => theme.breakpointDown.sm} {
    min-height: 72px;
    border-bottom: 0px !important;
  }
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  transition: 0.5s ease all;
  background-color: white;
  &.scrolled {
    background-color: white;
  }
`

const StraplineImg = styled.img`
  margin-bottom: 0;
  width: 486px;
`

const Logo = styled.img`
  margin-bottom: 0px;
  width: 195px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-left: 11px;
    width: 236px;
  }
`
const StrapWrapper = styled.div`
  /* pointer-events: none; */
  padding-bottom: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* bottom: -50%; */
  bottom: -120px;
  left: -30px;
  width: 483px;
  height: 100px;

  @media ${({ theme }) => theme.breakpointDown.md} {
    bottom: -80px;
    left: -20px;
    width: 293px;
    height: 70px;
    border-bottom-right-radius: 20px;
  }
  background: #ffffff;
  /* background-color: red; */
  z-index: 998;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;

  transition: opacity 0.8s ease-in-out;
  &.notscrolled {
    opacity: 1;
  }
  &.scrolled {
    opacity: 0;
  }
`
const MainWrapper = styled.header`
  position: relative;
  margin-bottom: 30px;
  @media screen and (max-width: 1280px) {
    margin-bottom: 0px;
  }
`
const StyledContainer = styled(Container)`
  position: relative;
`
const Line = styled.div`
  position: absolute;
  display: block;
  width: 50vw;
  height: 100%;
  left: calc(-50vw - 30px);
  top: -4px;
  /* z-index: 0; */
  background: #eb7c00;
  @media ${({ theme }) => theme.breakpointDown.md} {
    width: 200vw;
    left: -100vw;
    top: -20px;
    height: 10px;
  }
  @media ${({ theme }) => theme.breakpoint.sm} {
    @media ${({ theme }) => theme.breakpointDown.md} {
      top: -31px;
    }
  }
`

const StyledSVG = styled.svg`
  position: relative;
  top: -0;
  .cls-1 {
    fill: none;
    stroke: #a82020;
  }
  .cls-2 {
    fill: #ea7c01;
    stroke: #ea7c01;
    /* stroke-width: 10px; */
  }
`

const StyledBringing = styled.img`
  width: 80%;
  margin: 0 auto;
`
const Header = props => {
  const [scroll, setScroll] = useState(false)
  const Sweep = useRef()
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 2)
    })

    gsap.registerPlugin(DrawSVGPlugin)
    gsap.registerPlugin(MotionPathPlugin)
    // gsap.registerPlugin(MotionPathHelper)
    // MotionPathHelper.create("#sweep")
    window.DrawSVGPlugin = DrawSVGPlugin
    // setTimeout(function () {
    const tl = gsap.timeline({
      delay: 1,
      repeat: 0,
      yoyo: false,
      ease: "none",
    })
    // tl.set("#Layer_1", { xPercent: -50, yPercent: -50 })
    tl.to("#sweep", 0, { opacity: 1, ease: "none" })
    tl.from("#mask1", 0.5, { drawSVG: 0, ease: "none" })
    tl.from("#mask2", 0.5, { drawSVG: 0, ease: "none" })
    tl.from("#mask3", 0.5, { drawSVG: 0, ease: "none" })

    const tl2 = gsap.timeline({
      delay: 1,
      repeat: 0,
      yoyo: false,
      ease: Power1.easeIn,
    })
    // tl.set("#Layer_1", { xPercent: -50, yPercent: -50 })
    // tl.to("#line", 3, { opacity: 1, ease: "none", duration: 3 })

    // tl2.fromTo(
    //   "#line",
    //   2,
    //   { left: "-100vw", opacity: 0 },
    //   { left: "calc(-50vw - 30px)", opacity: 1 }
    // )
    // tl.from("#sweep", { x: -50 })
    // tl.from("#sweep", 4, { drawSVG: "50% 50%" })
    // gsap.fromTo(
    //   "#sweep",
    //   { drawSVG: "0%" },
    //   { duration: 1, drawSVG: "100%", immediateRender: false }
    // )
    // }, 1000)
  }, [])
  // console.log(version)
  const modalContext = useContext(ModalContext)
  return (
    <>
      <MainWrapper
        className={modalContext.modalState ? `modal-open` : undefined}
      >
        <HeaderWrapper className={scroll ? "scrolled" : "scrolled"}>
          <StyledContainer overflowDisabled={true}>
            <Line id="line" />
            <Row>
              <LeftSide xs={12} lg={4} xl={4}>
                <Link to="/">
                  <Logo src={LogoImage} />
                </Link>
                <MobileMenu />
              </LeftSide>
              <RightSide hiddenDown="md" md={8} xl={8}>
                <Menu />
              </RightSide>
            </Row>
            {/* <Hidden smDown> */}
            <StrapWrapper className={scroll ? "scrolled" : "notscrolled"}>
              <StyledBringing src={asset} />
            </StrapWrapper>
            {/* </Hidden> */}
          </StyledContainer>
        </HeaderWrapper>
      </MainWrapper>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
