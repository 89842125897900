import styled, { css } from "styled-components"
import * as mixins from "~styles/mixins"

const Col = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}
  ${({ overflowDisabled }) =>
    overflowDisabled &&
    css`
      overflow: visible !important;
    `}

    ${({ theme, hiddenDown }) => {
    let breakpoint = null
    if (typeof hiddenDown !== "undefined") {
      switch (hiddenDown) {
        case "sm":
          breakpoint = theme.breakpointDown.sm
          break
        case "md":
          breakpoint = theme.breakpointDown.md
          break
        case "lg":
          breakpoint = theme.breakpointDown.lg
          break
        case "xl":
          breakpoint = theme.breakpointDown.xl
          break
        default:
          break
      }
    }
    return (
      hiddenDown &&
      `
    @media ${breakpoint} {
        display: none;
    }
    `
    )
  }}

    ${({ xs }) =>
    xs &&
    `
        ${mixins.makeCol(xs, 12)}
    `}

    ${({ theme, sm }) =>
    sm &&
    `
    @media ${theme.breakpoint.sm} {
        ${mixins.makeCol(sm, 12)}
    }
    `}

    ${({ theme, md }) =>
    md &&
    `
    @media ${theme.breakpoint.md} {
        ${mixins.makeCol(md, 12)}
    }
    `}

    ${({ theme, lg }) =>
    lg &&
    `
    @media ${theme.breakpoint.lg} {
        ${mixins.makeCol(lg, 12)}
    }
    `}

    ${({ theme, xl }) =>
    xl &&
    `
    @media ${theme.breakpoint.xl} {
        ${mixins.makeCol(xl, 12)}
    }
    `}
`

export default Col
