import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import Burger from "./Burger"

import MenuItem from "./MenuItem"
import { StaticQuery, graphql } from "gatsby"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
const MENU_QUERY = graphql`
  fragment MenuItem on WPGraphQL_MenuItem {
    id
    label
    url
    title
    target
  }

  query GETMAINMENU {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          ...MenuItem
        }
      }
      generalSettings {
        url
      }
    }
  }
`

const Wrapper = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
`

const MobileMenus = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 72, 117, 0.95);
  transition: 0.5s ease all;
  transform: translateY(-100%);
  ${props =>
    props.checked &&
    css`
      transform: translateY(-0%);
    `};
  padding: 40px 7.5px;
  color: white;
`
const options = {
  reserveScrollBarGap: true, //true
}
const MobileMenu = () => {
  const ref = useRef(null)
  const [checked, setChecked] = useState(false)
  const toggleChecked = () => setChecked(value => !value)

  useEffect(() => {
    if (checked) {
      disableBodyScroll(ref.current, options)
      // console.log("locking body")
      // document.documentElement.style.overflow = "hidden"
      // document.body.style.overflow = "hidden"
    } else {
      clearAllBodyScrollLocks()
      // console.log("unlocking body")
      // document.documentElement.style.overflow = "unset"
      // document.body.style.overflow = "unset"
    }
  }, [checked])

  return (
    <div>
      <Burger
        onClick={toggleChecked}
        checked={checked}
        change={toggleChecked}
      />
      <Wrapper ref={ref}>
        <MobileMenus checked={checked}>
          <StaticQuery
            query={MENU_QUERY}
            render={data => {
              if (data.wpgraphql.menuItems) {
                const menuItems = data.wpgraphql.menuItems.nodes
                const wordPressUrl = data.wpgraphql.generalSettings.url

                return (
                  <div>
                    {menuItems &&
                      menuItems.map(menuItem => (
                        <MenuItem
                          mobile="true"
                          key={menuItem.id}
                          menuItem={menuItem}
                          wordPressUrl={wordPressUrl}
                          onClick={() => {
                            toggleChecked()
                          }}
                        />
                      ))}
                  </div>
                )
              }
              return null
            }}
          />
        </MobileMenus>
      </Wrapper>
    </div>
  )
}

export default MobileMenu
