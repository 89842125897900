import React from "react"
import styled from "styled-components"

const Wrapper = styled.main``

const Main = ({ children, version, ...props }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Main
