import React from "react"
import styled, { css } from "styled-components"

import { CreateLocalLink } from "../utils"
import UniversalLink from "./UniversalLink"

const NavItem = styled(UniversalLink)`
  padding: 0;
  /* margin: 0 16px; */
  color: white;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  flex-shrink: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 2em;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  text-align: center;
  /* margin-right: 20px; */
  padding-left: 17px;
  padding-right: 17px;
  @media ${({ theme }) => theme.breakpoint.md} and ${({ theme }) =>
      theme.breakpointDown.xl} {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  letter-spacing: 0.20000005px;
  transition: 1s;
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    &:last-child {
      opacity: 1;
    }
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    &:hover:not(.active) {
      color: #ee751a;
      background-color: white;
      box-shadow: inset 0px 0px 0px 2px #ee751a;
    }
  }

  /* @media only screen and (max-width: 425px) { */
  @media ${({ theme }) => theme.breakpointDown.lg} {
    color: white;
    font-size: 18px !important;
  }

  &:first-of-type {
    margin-left: 0 !important;
  }

  &:last-of-type {
    margin-right: 0 !important;
    @media ${({ theme }) => theme.breakpoint.md} {
      &.active {
        background: #ee751a !important;
      }
    }
    background: #003d6a;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
    padding-left: 17px;
    padding-right: 17px;
    color: white;

    @media screen and (max-width: 1236px) {
      & {
        /* padding-left: 15px; */
        /* padding-right: 15px; */
        /* padding: 0 !important; */
        /* margin: 0 !important; */
      }
      &:last-child() {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  @media screen and (min-width: 991.02px) and (max-width: 1130px) {
    & {
      margin-right: 10px !important;
      font-size: 16px !important;
    }
  }
  ${props =>
    props.mobile &&
    css`
      width: 100%;
      /* width: 70%; */
      text-align: left;
      text-transform: uppercase;
      margin-left: 0px;
      font-size: 1rem;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0px;
      }
      &:last-of-type {
        border: 0px;
        /* padding: 0px; */
        background: transparent;
      }
    `};

  &:nth-child(1) {
    background-color: #76c5b7;
  }
  &:nth-child(2) {
    background-color: #eb7c00;
  }
  &:nth-child(3) {
    background-color: #c1d100;
  }
  &:nth-child(4) {
    background-color: #92d1f4;
  }
  &:nth-child(5) {
    background-color: rgb(99, 102, 106);
  }
  &:nth-child(6) {
    background-color: rgb(200, 201, 199);
  }
  border: 0px;
`
const MenuItem = ({ menuItem, wordPressUrl, mobile, ...props }) => {
  // console.log("menuItem>>>>>>>", menuItem)
  return (
    <NavItem
      mobile={mobile}
      to={CreateLocalLink(menuItem, wordPressUrl)}
      activeClassName="active"
      {...props}
    >
      {menuItem.label}
    </NavItem>
  )
}

export default MenuItem
