import { breakpoint, gutterSize, containerSize } from "./theme"

export const makeContainer = () => {
  return `
        width: 100%;

        margin-left: auto;
        margin-right: auto;

        padding-left: ${gutterSize.xs / 2}px;
        padding-right: ${gutterSize.xs / 2}px;

        @media ${breakpoint.md} {
            padding-left: ${gutterSize.md / 2}px;
            padding-right: ${gutterSize.md / 2}px;
        }
    `
}

export const makeContainerMaxWidths = () => {
  let result = ""

  Object.keys(breakpoint).forEach(index => {
    result += `
            @media ${breakpoint[index]} {
                max-width: ${containerSize[index]}
            }
        `
  })

  return result
}

export const makeRow = () => {
  return `
        display:flex;
        flex: 1 0 100%;
        flex-wrap: wrap;

        margin-left: -${gutterSize.xs / 2}px;
        margin-right: -${gutterSize.xs / 2}px;

        @media ${breakpoint.md} {
            margin-left: -${gutterSize.md / 2}px;
            margin-right: -${gutterSize.md / 2}px;
        }
    `
}

export const makeColReady = () => {
  return `
        position: relative;
        width: 100%;

        padding-left: ${gutterSize.xs / 2}px;
        padding-right: ${gutterSize.xs / 2}px;

        @media ${breakpoint.md} {
            padding-left: ${gutterSize.md / 2}px;
            padding-right: ${gutterSize.md / 2}px;
        }
    `
}

export const makeCol = (size = 12, columns = 12) => {
  let colsize = (size / columns) * 100
  if (Number.isNaN(colsize)) {
    colsize = 100
  }
  return `
        flex: 0 0 ${colsize}%;
        max-width: ${colsize}%;
    `
}

export const makeColOffset = (size = 12, columns = 12) => {
  const num = size / columns
  return `
        margin-left: ${num === 0 ? 0 : num * 100}%;
    `
}
