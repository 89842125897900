import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const ButtonText = styled.div`
  position: relative;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &::before {
    transition: all 0.3s ease-in-out;
    margin: 0px 0px 0px 0px;
    opacity: 1;
    ${props => (props.initialText ? `content: "${props.initialText}";` : "")}
  }

  &::after {
    ${props => (props.changedText ? `content: "${props.changedText}";` : "")}
    position: absolute;
    left: 50%;
    right: 0;
    text-align: center;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`

const StyledButtonLink = styled(Link)`
  color: ${props => props.color};
  box-sizing: border-box;
  flex-shrink: 0;
  display: inline-block;
  border: 2px solid ${props => props.borderColor};
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.16px;

  ${({ solid, borderColor }) =>
    solid &&
    `
    background: ${borderColor} !important;
    color: white;
  `}

  @media ${({ theme }) => theme.breakpointDown.md} {
    background: ${props => props.borderColor} !important;
    color: white;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.18px;
  }
  .button-container {
    display: inline-block;
    cursor: pointer;
    -webkit-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;

    .button {
      border-radius: 3px;
      position: relative;
      top: 0;
      left: 0px;
      padding: 8px 16px 8px 16px;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      @media ${({ theme }) => theme.breakpointDown.md} {
        margin-bottom: 2px;
      }
    }
  }

  .button-slide-horizontal {
    @media ${({ theme }) => theme.breakpoint.md} {
      ${props =>
        props.noAnimation
          ? ``
          : `      
            &:hover .buttonText:before {
              margin: 0px 50px 0px -50px;
              opacity: 0;
              left: 40px;
            }

            &:hover .buttonText:after {
              left: 0%;
              opacity: 1;
            }
        `}
    }
  }
`

const ButtonLink = ({
  color,
  borderColor,
  initialText,
  changedText,
  target,
  noAnimation,
  solid,
  ...props
}) => {
  return (
    <StyledButtonLink
      color={color}
      target={target}
      borderColor={borderColor}
      noAnimation={noAnimation}
      solid={solid}
      {...props}
    >
      <div className="button-container button-slide-horizontal">
        <div className="slider slider-horizontal">
          <div className="button">
            <ButtonText
              className="buttonText"
              initialText={initialText}
              changedText={changedText}
            />
          </div>
        </div>
      </div>
    </StyledButtonLink>
  )
}

export default ButtonLink
