import "./scrollbar.css"

import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import Scrollbar from "react-scrollbars-custom"
import { useRecoilState } from "recoil"
import styled from "styled-components"

import modalAtom from "~atoms/modalAtom"
import newsAtom from "~atoms/newsAtom"
import ButtonWithRoundedEdges from "~components/ButtonWithRoundedEdges"
import arrow from "~images/arrow2x.png"

import ModalContext from "../contexts/ModalContext"
const options = {
  reserveScrollBarGap: true, //true
}

function getOrdinalNum(n) {
  return (
    n +
    (n > 0
      ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : "")
  )
}

const Accept = styled(ButtonWithRoundedEdges)`
  color: rgb(111, 194, 180);
`

const Modal = ({ normalContent, content }) => {
  const modalContext = useContext(ModalContext)
  const ref = useRef(null)
  const [currentNew, setCurrentNews] = useRecoilState(newsAtom)
  const [modal, setModal] = useRecoilState(modalAtom)

  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          themeGeneralSettings {
            theme_settings {
              fieldGroupName
              termsConditions
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (modalContext.modalState) {
      disableBodyScroll(ref.current, options)
    } else {
      clearAllBodyScrollLocks()
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [modalContext.modalState])

  let date = new Date(currentNew.date)
  var mm = date.toLocaleString("default", { month: "long" })
  var dd = date.getDate()
  var yy = date.getFullYear()

  dd = getOrdinalNum(dd)

  date = dd + " " + mm + " " + yy

  return (
    <Wrapper>
      <Mask
        active={modalContext.modalState}
        // onClick={() => modalContext.modalDispatch("close")}
      />
      <ModalBody ref={ref}>
        {!modal.normal && (
          <>
            <CloseButton onClick={() => modalContext.modalDispatch("close")}>
              <CloseIcon src={arrow} />
              Close
            </CloseButton>
            <ParentWrapper momentum>
              <ImgWrapper>
                <img
                  className="hero"
                  src={currentNew?.featuredImage?.node?.sourceUrl}
                  alt={currentNew?.featuredImage?.node?.altText}
                />

                <InfoWrapper>
                  <Element>{date}</Element>
                  <Element>
                    {currentNew?.newsItem?.city}
                    {currentNew?.newsItem?.city !== null &&
                    currentNew?.newsItem?.country !== null
                      ? ", "
                      : null}
                    {currentNew?.newsItem?.country}
                  </Element>
                </InfoWrapper>
              </ImgWrapper>
              <ContentWrapper>
                <TCHeading>{currentNew?.title}</TCHeading>
                <Content
                  dangerouslySetInnerHTML={{ __html: currentNew?.content }}
                ></Content>
              </ContentWrapper>
            </ParentWrapper>
          </>
        )}
        {modal.normal && (
          <ParentWrapper momentum>
            <ContentWrapper>
              {/* <TCHeading>{modal?.title}</TCHeading> */}
              <ContentTC
                dangerouslySetInnerHTML={{ __html: modal?.content }}
              ></ContentTC>
              <Accept
                color="#6fc2b4"
                style={{
                  // float: "right",
                  marginBottom: "20px",
                  position: "relative",
                }}
                onClick={() => modalContext.modalDispatch("close")}
                initialText="Accept the T&Cs"
              />
            </ContentWrapper>
          </ParentWrapper>
        )}
      </ModalBody>
    </Wrapper>
  )
}

const Element = styled.span`
  font-size: 14px;
  & + & {
    margin-left: 35px;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`

const InfoWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 396px;
  height: 34px;
  background: #6fc2b4;
  color: white;
  padding-left: 15px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 42px;
  }
  padding-right: 25px;
  display: flex;
  align-items: center;
  border-top-right-radius: 25px;
`
const CloseButton = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  color: #ffffff;
  font-family: Palanquin;
  text-align: right;
  font-size: 14px;
  font-weight: medium;
  line-height: 20px;
  width: 107px;
  height: 34px;
  background: #ee751a;
  /* border-top-right-radius: 36px; */
  border-bottom-left-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3px;
  &:hover {
    cursor: pointer;
  }
  z-index: 999;
`

const CloseIcon = styled.img`
  width: 6px;
  margin-bottom: 0;
  margin-right: 6px;
`

const ImgWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 0px 0px 0px 25px;
  img.hero {
    height: 100%;
    max-height: 355px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 0;
    @media ${({ theme }) => theme.breakpointDown.md} {
      height: 250px;
    }
  }
`
const InnerContent = styled.div`
  padding: 0 20px 0 0;
`

const TCHeading = styled.h3`
  color: #003d6a;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }
`

const ContentWrapper = styled.div`
  padding: 10px 15px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 31px 42px;
  }
  height: 100%;
  width: 100%;
`

const ContentTC = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 250;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }
  h3 {
    color: rgb(0, 61, 106);
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 250;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }
`

const Wrapper = styled.div`
  position: fixed;
  z-index: 99999999;
`

const Close = styled.button`
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 30px;
  color: #003d6a;
  font-size: 32px;
  cursor: pointer;
  background: transparent;
  border: 0;
  transform: rotate(45deg);
  outline: none;
`
const ParentWrapper = styled(Scrollbar)`
  overflow: auto;
`
const ModalBody = styled.div`
  position: fixed;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: 0.9s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);

  transition: opacity 0.5s ease-out;
  transform: translateY(45px);
  background: #ffffff;
  /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); */
  /* padding: 30px 16px 30px 30px; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  top: 0;
  bottom: 0;
  /* width: 50vw; */
  /* height: 50vh; */

  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  overflow: hidden;
  width: 90%;
  /* height: 100; */
  max-height: 65vh;
  @media ${({ theme }) => theme.breakpoint.md} {
    max-width: 872px;
    height: 782px;
    max-height: 80vh;
  }
`

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4416);

  z-index: 50;
  visibility: hidden;
  opacity: 0;
  transition: 1s;
  z-index: 99;
  ${({ active }) => {
    if (active) {
      return `
            visibility: visible;
            opacity: 1;
            &+${ModalBody} {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
          `
    }
  }};
`

export default Modal
