import React from "react"
import { bool, func } from "prop-types"
import styled from "styled-components"

export const StyledBurger = styled.button`
  @media screen and (min-width: 992px) {
    & {
      display: none;
    }
  }
  position: absolute;
  /* right: calc(11px + 7.5px); */
  right: 7.5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 31px;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9999;

  span {
    width: 31px;
    height: 3px;
    background: #14306b;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 3px;
    border-bottom-right-radius: 25%;
    border-top-left-radius: 25%;

    :first-child {
      transform: ${({ checked }) => (checked ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ checked }) => (checked ? "0" : "1")};
      transform: ${({ checked }) =>
        checked ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ checked }) => (checked ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Burger = ({ checked, change, ...props }) => {
  const isExpanded = checked ? true : false

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      checked={checked}
      onClick={() => change(!checked)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  )
}

Burger.propTypes = {
  checked: bool.isRequired,
  change: func.isRequired,
}

export default Burger
