/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./layout.css"
import "./typography.css"

import PropTypes from "prop-types"
import React, { useEffect, useReducer, useState } from "react"
import CookieConsent from "react-cookie-consent"
import {
  atom,
  RecoilRoot,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"

import ButtonLink from "~components/ButtonLink"

import ModalContext, {
  initialState as modalInitialState,
  reducer as modalReducer,
} from "../contexts/ModalContext"
import GlobalStyle from "../styles/GlobalStyle"
import { theme } from "../styles/theme"
import Footer from "./Footer"
import Header from "./Header"
import Main from "./Main"
import Modal from "./Modal"

const Wrapper = styled.main`
  &.withbg {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      background-image: linear-gradient(to bottom, #eb7c00, rgba(255, 0, 0, 0));
    }
  }
`

const Layout = ({ children, locationSearch, ...props }) => {
  const [modalState, modalDispatch] = useReducer(
    modalReducer,
    modalInitialState
  )

  const [version, setVersion] = useState(null)

  useEffect(() => {
    setVersion(new URLSearchParams(locationSearch).get("version"))
  }, [version])

  return (
    <div>
      <RecoilRoot>
        <GlobalStyle />
        <ThemeProvider theme={theme} {...props}>
          <ModalContext.Provider
            value={{ modalState: modalState, modalDispatch: modalDispatch }}
          >
            <Header version={version} />
            <Main version={version}>{children}</Main>
            <Footer />
            <Modal />
            <CookieConsent
              style={{
                background: "#003D6A",
                borderTop: "1px solid white",
                fontSize: "0.8em",
                textAlign: "center",
              }}
              buttonStyle={{
                background: "#EE751A",
                color: "white",
                // display: "none",
              }}
            >
              We use cookies to ensure that we give you the best experience on
              our website. If you continue to use this site we will assume that
              you are happy with it.
              <ButtonLink
                color="#FFFFFF"
                initialText="Cookie Policy"
                changedText="Click Here"
                noAnimation
                to="/cookie-policy"
                solid
              />
              {/* <ButtonLink
                style={{ marginLeft: "20px" }}
                color="#EA7C01"
                initialText="OK"
              /> */}
            </CookieConsent>
          </ModalContext.Provider>
        </ThemeProvider>
      </RecoilRoot>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
