import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { theme } from "~styles/theme"
const breakpointMap = new Map()

/** Temporary static to make the process faster */
// The map is here to guarantee order
breakpointMap.set("xs", 0)
breakpointMap.set("sm", theme.breakpointRaw.sm.replace(/\D/g, ""))
breakpointMap.set("md", theme.breakpointRaw.md.replace(/\D/g, ""))
breakpointMap.set("lg", theme.breakpointRaw.lg.replace(/\D/g, ""))
breakpointMap.set("xl", theme.breakpointRaw.xl.replace(/\D/g, ""))

const currentBreakpoint = width => {
  let result = [...breakpointMap][breakpointMap.size - 1][0]

  for (let [key, value] of breakpointMap) {
    if (width < value) {
      break
    } else {
      result = key
    }
  }

  return result
}

// By default, returns true if screen width is the same or greater than the given breakpoint.
var isWidthUp = function isWidthUp(breakpoint, width) {
  var inclusive =
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true

  let breakpoints = Array.from(breakpointMap.keys())

  if (inclusive) {
    return breakpoints.indexOf(breakpoint) <= breakpoints.indexOf(width)
  }

  return breakpoints.indexOf(breakpoint) < breakpoints.indexOf(width)
} // By default, returns true if screen width is the same or less than the given breakpoint.

var isWidthDown = function isWidthDown(breakpoint, width) {
  var inclusive =
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true

  let breakpoints = Array.from(breakpointMap.keys())

  if (inclusive) {
    return breakpoints.indexOf(width) <= breakpoints.indexOf(breakpoint)
  }

  return breakpoints.indexOf(width) < breakpoints.indexOf(breakpoint)
}

const getWindowDimensions = () => {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }
}

const Hidden = props => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  let width = currentBreakpoint(windowDimensions?.width)
  let visible = true
  let only = props.only

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  if (only) {
    if (Array.isArray(only)) {
      for (let i = 0; i < only.length; i += 1) {
        let breakpoint = only[i]

        if (width === breakpoint) {
          visible = false
          break
        }
      }
    } else if (only && width === only) {
      visible = false
    }
  }

  let breakpoints = Array.from(breakpointMap.keys())
  if (visible) {
    // determine visibility based on the smallest size up
    for (var _i = 0; _i < breakpoints.length; _i += 1) {
      var _breakpoint = breakpoints[_i]
      var breakpointUp = props["".concat(_breakpoint, "Up")]
      var breakpointDown = props["".concat(_breakpoint, "Down")]
      // console.log(width)
      if (
        (breakpointUp && (0, isWidthUp)(_breakpoint, width)) ||
        (breakpointDown && (0, isWidthDown)(_breakpoint, width))
      ) {
        visible = false
        break
      }
    }
  }

  if (!visible) {
    return null
  }

  return props.children
}

Hidden.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  implementation: PropTypes.oneOf(["js", "css"]),
  initialWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  xlDown: PropTypes.bool,
  xlUp: PropTypes.bool,
  lgDown: PropTypes.bool,
  lgUp: PropTypes.bool,
  mdDown: PropTypes.bool,
  mdUp: PropTypes.bool,
  only: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  ]),
  smDown: PropTypes.bool,
  smUp: PropTypes.bool,
  xsDown: PropTypes.bool,
  xsUp: PropTypes.bool,
}

export default Hidden
