import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  border: 2px solid ${props => props.color};
  color: ${props => props.color};

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  &:hover {
    cursor: pointer;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 8px 16px 8px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  letter-spacing: 0.18px;
`

const ButtonText = styled.div`
  position: relative;
  text-align: center;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &::before {
    transition: all 0.3s ease-in-out;
    margin: 0px 0px 0px 0px;
    opacity: 1;
    ${props => (props.initialText ? `content: "${props.initialText}";` : "")}
  }

  &::after {
    ${props => (props.changedText ? `content: "${props.changedText}";` : "")}
    position: absolute;
    left: 50%;
    right: 0;
    text-align: center;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`

const StyledButtonLink = styled.div`
  background-color: ${props => props.color};
  box-sizing: border-box;
  flex-shrink: 0;
  display: inline-block;
  // border: 2px solid ${props => props.borderColor};
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  border:0;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.16px;

  @media ${({ theme }) => theme.breakpointDown.md} {
    background: ${props => props.borderColor} !important;
    color: white;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.18px;
  }
  .button-container {
    display: inline-block;
    cursor: pointer;
    -webkit-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;

    .button {
      border-radius: 3px;
      position: relative;
      top: 0;
      left: 0px;
      padding: 8px 16px 8px 16px;
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
    }
  }
@media ${({ theme }) => theme.breakpoint.md} {
  ${props =>
    !props.disableAnimation &&
    `
  .button-slide-horizontal {
    &:hover .buttonText:before {
      margin: 0px 50px 0px -50px;
      opacity: 0;
      left: 40px;
    }
    &:hover .buttonText:after {
      left: 0%;
      opacity: 1;
    }`}
  }
}
`

const ButtonWithRoundedEdges = ({
  initialText,
  disableAnimation,
  ...props
}) => {
  return (
    <StyledButtonLink disableAnimation={disableAnimation} {...props}>
      <div className="button-container button-slide-horizontal">
        <div className="slider slider-horizontal">
          <div className="button">
            <ButtonText
              className="buttonText"
              initialText={initialText}
              changedText={"Click here"}
            />
          </div>
        </div>
      </div>
    </StyledButtonLink>
  )
}

export default ButtonWithRoundedEdges
