import React from "react"

export default React.createContext()

export const initialState = false
export const reducer = (state, action) => {
  switch (action) {
    case "open":
      return true
    case "close":
      return false
    case "toggle":
      return !state
    default:
      return state
  }
}
