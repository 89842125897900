import React from "react"
import { StaticQuery, graphql } from "gatsby"
import MenuItem from "./MenuItem"
import FooterMenuItem from "./FooterMenuItem"

import styled from "styled-components"

const MENU_QUERY3 = graphql`
  fragment MenuItem on WPGraphQL_MenuItem {
    id
    label
    url
    title
    target
  }

  query GETMAINMENU3 {
    wpgraphql {
      menuItems(where: { location: FOOTER }) {
        nodes {
          ...MenuItem
        }
      }
      generalSettings {
        url
      }
    }
  }
`
const NavContainer = styled.nav`
  list-style: none;
  display: flex;
  justify-content: space-between;
  /* width: 848px; */
  max-width: 100%;
  box-sizing: border-box;
  /* padding: 0 24px; */
  margin: 0;
  width: 100%;
  flex-direction: column;
  text-align: left;
`

const FooterMenu = () => {
  return (
    <StaticQuery
      query={MENU_QUERY3}
      render={data => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
            <NavContainer>
              {menuItems &&
                menuItems.map(menuItem => (
                  <FooterMenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    wordPressUrl={wordPressUrl}
                  />
                ))}
            </NavContainer>
          )
        }
        return null
      }}
    />
  )
}

export default FooterMenu
