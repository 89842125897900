import Bold from "./Palanquin-Bold.woff"
import Bold2 from "./Palanquin-Bold.woff2"

import Light from "./Palanquin-Light.woff"
import Light2 from "./Palanquin-Light.woff2"

import Regular from "./Palanquin-Regular.woff"
import Regular2 from "./Palanquin-Regular.woff2"

import Medium from "./Palanquin-Medium.woff"
import Medium2 from "./Palanquin-Medium.woff2"

import SemiBold from "./Palanquin-SemiBold.woff"
import SemiBold2 from "./Palanquin-SemiBold.woff2"

export default {
  bold: Bold,
  bold2: Bold2,
  light: Light,
  light2: Light2,
  regular: Regular,
  regular2: Regular2,
  medium: Medium,
  medium2: Medium2,
  semibold: SemiBold,
  semibold2: SemiBold2,
}
