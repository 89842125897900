import styled, { css } from "styled-components"
import * as mixins from "~styles/mixins"

const Container = styled.div`
  ${mixins.makeContainer}
  ${mixins.makeContainerMaxWidths}
  overflow: hidden;
  /* padding-top: 2rem;
  padding-bottom: 2rem; */

  ${({ maxWidth }) =>
    maxWidth === false &&
    css`
      max-width: none !important;
    `}

  ${({ overflowDisabled }) =>
    overflowDisabled &&
    css`
      overflow: visible !important;
    `}

  ${({ disableGutters }) =>
    disableGutters &&
    css`
      padding-left: 0px !important;
      padding-right: 0px !important;
    `}
`

export default Container
