import React from "react"
import { CreateLocalLink } from "../utils"
import UniversalLink from "./UniversalLink"
import styled, { css } from "styled-components"

const NavItem = styled(UniversalLink)`
  display: inline-block;
  text-align: left;
  color: white;
  text-decoration: none;

  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.4375px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
  &:first-of-type {
    margin-left: 0 !important;
  }

  ${props =>
    props.mobile &&
    css`
      width: 70%;
      text-align: left;
      text-transform: uppercase;
      margin-left: 0px;
      font-size: 1.3rem;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0px;
      }
      &:last-of-type {
        border: 0px;
        /* padding: 0px; */
        background: transparent;
      }
    `};
`
const FooterMenuItem = ({ menuItem, wordPressUrl, mobile, ...props }) => {
  return (
    <NavItem
      mobile={mobile}
      to={CreateLocalLink(menuItem, wordPressUrl)}
      {...props}
    >
      {menuItem.label}
    </NavItem>
  )
}

export default FooterMenuItem
