export const colors = {
  // white: "#fff", // White base
  // black: "#000", // Black base
  // primary: "#232424", // Text variant
  // secondary: "#F84682", // Text variant and theme colour, has to be HEX AABBCC
  // grey: "#fefefe", // Footer bg
  // grey100: "#fafafa", // Text variant // module background?
  // grey200: "#f1f1f1", // Social buttons (found in menu)
  // grey300: "#f5f5f5",
  // grey600: "#ABAAAD",
  // grey100rgba: "rgba(250,250,250,0.8)",
}

export const gutterSize = {
  xs: 15,
  md: 27,
}

export const containerSize = {
  sm: "540px",
  md: "720px",
  lg: "960px",
  xl: "1344px",
  // xxl: '1500px',
}

export const size = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1400px",
  // xxl: '1500px',
}

export const breakpoint = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  // xxl: `(min-width: ${size.xxl})`,
}

export const breakpointDown = {
  sm: `(max-width: 575.98px)`,
  md: `(max-width: 767.98px)`,
  lg: `(max-width: 991.98px)`,
  xl: `(max-width: 1399.98px)`,
  // xxl: `(min-width: 1200px)`,
}

export const typography = {
  // hero: {
  //   size: {
  //     xs: '2.5rem',
  //     md: '5rem',
  //   },
  //   lineHeight: {
  //     xs: '2.8125rem',
  //     md: '6rem',
  //   },
  // },
  // h1: {
  //   size: {
  //     xs: '2rem',
  //     md: '3.125rem',
  //   },
  //   lineHeight: {
  //     xs: '2.3125rem',
  //     md: '3.75rem',
  //   },
  // },
  // h2: {
  //   size: {
  //     xs: '1.125rem',
  //     md: '1.375rem',
  //   },
  //   lineHeight: {
  //     xs: '1.5625rem',
  //     md: '1.875rem',
  //   },
  // },
  // h3: {
  //   size: {
  //     xs: '1.5rem',
  //     md: '2rem',
  //   },
  //   lineHeight: {
  //     xs: '2.5rem',
  //     md: '2.5rem',
  //   },
  // },
  // copy: {
  //   size: {
  //     xs: '16px',
  //     md: '16px',
  //   },
  //   lineHeight: {
  //     xs: '1.6875rem',
  //     md: '1.6875rem',
  //   },
  // },
}

const hamburgerStyle = {
  // hPadding: '15px',
  // hWidth: '36px',
  // hHeight: '24px',
  // hBackgroundColor: 'transparent',
  // hLineColor: '#000',
  // hHoverLineColor: colors.secondary,
  // hLineHeight: '2px',
  // hBorderRadius: '4px',
  // hTransitionTime: '0.4s',
  // hTransitionFunction: 'ease',
  // hTransitionOpacity: '0',
  // hVerticalGap: '13px',
  // hInitialOpposition: '5px',
  // hHideDistance: 51, // hWidth+hPadding
}

export const theme = {
  colors: { ...colors },
  containerSize: { ...containerSize },
  gutterSize: { ...gutterSize },
  size: { ...size },
  breakpointRaw: { ...size },
  breakpoint: { ...breakpoint },
  breakpointDown: { ...breakpointDown },
  font: {
    weight: {
      light: "200",
      regular: "normal",
      bold: "bold",
    },
    typography: { ...typography },
  },
  hamburger: { ...hamburgerStyle },
}
