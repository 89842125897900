import { createGlobalStyle } from "styled-components"

import fonts from "../fonts"

export default createGlobalStyle`
    @font-face {
        font-family: 'Palanquin';
        src: url(${fonts.light2}) format('woff2'),
            url(${fonts.light}) format('woff');
        font-weight: 300;
        font-display: swap;
    }


    @font-face {
        font-family: 'Palanquin';
        src: url(${fonts.regular2}) format('woff2'),
            url(${fonts.regular}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Palanquin';
        src: url(${fonts.medium2}) format('woff2'),
            url(${fonts.medium}) format('woff');
        font-weight: medium;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Palanquin';
        src: url(${fonts.semibold2}) format('woff2'),
            url(${fonts.semibold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Palanquin';
        src: url(${fonts.bold2}) format('woff2'),
            url(${fonts.bold2}) format('woff');
        font-weight: 700;
        font-display: swap;
    }


`
